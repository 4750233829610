<template>
  <LoadingMingguanForm mode="Tambah" module="Data Loading Mingguan"> </LoadingMingguanForm>
</template>

<script>
import LoadingMingguanForm from './form';

const LoadingMingguanAdd = {
  name: 'LoadingMingguanAdd',
  components: { LoadingMingguanForm },
};

export default LoadingMingguanAdd;
</script>
